import * as WebBrowser from "expo-web-browser";
import {
  Button,
  Container,
  List,
  ListItem,
  Text,
  ActionSheet,
} from "native-base";
import React, { useEffect, useState } from "react";
import { Platform, View } from "react-native";
import moment from "moment";
import { useAuth } from "../../AuthContext";
import AppActivityIndicator from "../../shared/AppActivityIndicator";
import AppHeader from "../../shared/AppHeader";
import AppTitle from "../../shared/AppTitle";
import DashboardContent from "../../shared/DashboardContent";
import * as Linking from "expo-linking";

import { deleteAccount } from "../../http/events";
import { eventLivePageBase } from "../../globalUtils";

const environment = process.env.NODE_ENV;

const Profile = ({ navigation, route }) => {
  const [loading, setloading] = useState(false);
  const {
    credits,
    name,
    email,
    first_name,
    last_name,
    signOut,
    refetchProfile,
    openWebFromApp,
    account_type,
    setFirstLoad,
    subscription_status,
    subscription_access_expiry_datetime,
    subscription_plan,
    stripeCustomerId,
  } = useAuth();

  const subActive =
    subscription_access_expiry_datetime > moment().utc().format();

  const env = environment === "production" ? "prod" : "test";

  // const [eventStats, seteventStats] = useState({});

  const review = async () => {
    const isAndroid =
      (Platform.OS === "web" &&
        navigator.userAgent.toLowerCase().indexOf("android") !== -1) ||
      Platform.OS === "android";

    await WebBrowser.openBrowserAsync(
      isAndroid
        ? "https://play.google.com/store/apps/details?id=pro.eventlive&reviewId=0"
        : "https://apps.apple.com/us/app/eventlive-livestream/id1457137042?ls=1&action=write-review"
    );
  };

  const buyCredits = async () => {
    navigation.navigate("BuyCredits");
  };

  const subscribe = async () => {
    navigation.navigate("Subscribe");
  };

  const deleteAcc = async () => {
    // ActionSheet confirm (!)
    // call mutation
    // sign out
    setloading(true);
    ActionSheet.show(
      {
        options:
          Platform.OS === "android"
            ? [
                {
                  text: "Delete my account, all personal data, and all video files streamed or uploaded online from EventLive servers",
                  icon: "trash",
                  iconColor: "#fa213b",
                },
                { text: "Cancel", icon: "close", iconColor: "#25de5b" },
              ]
            : [
                "Delete my account, all personal data, and all video files streamed or uploaded online from EventLive servers",
                "Cancel",
              ],
        cancelButtonIndex: 1,
        destructiveButtonIndex: 0,
        title: "Delete your Account? THIS ACTION IS IRREVERSIBLE",
      },
      async (buttonIndex) => {
        if (buttonIndex === 0) {
          try {
            const r = await deleteAccount();
            console.log(r);
            await signOut();
            setFirstLoad(true); // has to be set after sign out
          } catch (err) {
            console.log(err);
            setloading(false);
          }
        }
        if (buttonIndex === 1) {
          setloading(false);
        }
      }
    );
  };

  const refetchStats = async () => {
    setloading(true);
    // const stats = await fetchNumberOfEvents();
    // seteventStats(stats);
    await refetchProfile();
    setloading(false);
  };

  useEffect(() => {
    refetchStats();
  }, []);

  useEffect(() => {
    return navigation.addListener("focus", () => {
      refetchStats();
    });
  }, [navigation]);

  return (
    <Container>
      <AppHeader
        hasRightButton={true}
        renderRightButton={() => (
          <Button transparent onPress={signOut}>
            <Text>Sign Out</Text>
          </Button>
        )}
      />
      <DashboardContent>
        {loading && <AppActivityIndicator />}
        {!loading && (
          <View
            style={{
              flex: 1,
              flexDirection: "column",
              marginHorizontal: 44,
              maxWidth: 600,
              width: "100%",
              alignSelf: "center",
            }}
          >
            <View style={{ marginHorizontal: 16 }}>
              <AppTitle
                text={
                  !!first_name && !!last_name
                    ? `${first_name} ${last_name}`
                    : `Profile`
                }
                style={{
                  textAlign: "left",
                  marginVertical: 20,
                }}
              />
              {!loading && <Text style={{ marginBottom: 10 }}>{email}</Text>}
              {!loading &&
                !subActive &&
                (account_type === "business" || credits > 0) && (
                  <Text>
                    You have {credits || 0} event credit
                    {credits !== 1 ? "s" : ""}
                  </Text>
                )}

              {!loading && subActive && (
                <Text>
                  Unlimited Subscription is {subscription_status} (
                  {subscription_plan})
                </Text>
              )}
            </View>

            <List style={{ flex: 1, marginVertical: 30 }}>
              {/* Added to hide from apple review */}
              {!loading && !subActive && (
                <ListItem style={{ width: "100%", marginLeft: 0 }}>
                  <Button style={{ flex: 1 }} transparent onPress={buyCredits}>
                    <Text>Get Credits</Text>
                  </Button>
                </ListItem>
              )}
              {/* Added to hide from apple review */}
              {!loading && !subActive && Platform.OS !== "ios" && (
                <ListItem style={{ width: "100%", marginLeft: 0 }}>
                  <Button style={{ flex: 1 }} transparent onPress={subscribe}>
                    <Text>Subscribe to EventLive Unlimited</Text>
                  </Button>
                </ListItem>
              )}

              {account_type === "business" && (
                <ListItem style={{ width: "100%", marginLeft: 0 }}>
                  <Button
                    style={{ flex: 1 }}
                    transparent
                    onPress={() => navigation.navigate("VideoWall")}
                  >
                    <Text>My Video Wall</Text>
                  </Button>
                </ListItem>
              )}

              <ListItem style={{ width: "100%", marginLeft: 0 }}>
                <Button
                  style={{ flex: 1 }}
                  transparent
                  onPress={() => navigation.navigate("UpdateEmail")}
                >
                  <Text>Update Profile</Text>
                </Button>
              </ListItem>

              {!loading && subActive && (
                <ListItem style={{ width: "100%", marginLeft: 0 }}>
                  <Button
                    style={{ flex: 1 }}
                    transparent
                    onPress={async () => {
                      const url = `${eventLivePageBase}api/openStripePortal/${env}/${stripeCustomerId}`;

                      if (Platform.OS === "web") {
                        window.open(url, "_blank");
                      } else Linking.openURL(url);

                      // await WebBrowser.openBrowserAsync(
                      // );
                    }}
                  >
                    <Text>View Invoices and Manage Subscription</Text>
                  </Button>
                </ListItem>
              )}

              <ListItem style={{ width: "100%", marginLeft: 0 }}>
                <Button
                  style={{ flex: 1 }}
                  transparent
                  onPress={() =>
                    navigation.navigate("ForgotPassword", { email })
                  }
                >
                  <Text>Reset Password</Text>
                </Button>
              </ListItem>

              <ListItem style={{ width: "100%", marginLeft: 0 }}>
                <Button style={{ flex: 1 }} transparent onPress={review}>
                  <Text>Review EventLive</Text>
                </Button>
              </ListItem>

              <ListItem style={{ width: "100%", marginLeft: 0 }}>
                <Button style={{ flex: 1 }} transparent onPress={deleteAcc}>
                  <Text>Delete Account</Text>
                </Button>
              </ListItem>
            </List>
          </View>
        )}
      </DashboardContent>
    </Container>
  );
};

export default Profile;
