import moment from "moment-timezone";
import React, { useEffect, useState } from "react";
import { Button, Text } from "native-base";
import { Ionicons } from "@expo/vector-icons";
import useNow from "../hooks/useNow";

export const ClockIcon = (props) => (
  <Svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} {...props}>
    <Path d="M12.727 4.477h-1.5v8.349l5.419 2.576.644-1.355-4.563-2.169V4.477z" />
    <Path d="M12 .75C5.787.75.75 5.787.75 12S5.787 23.25 12 23.25 23.25 18.213 23.25 12 18.213.75 12 .75zm0 21c-5.385 0-9.75-4.365-9.75-9.75S6.615 2.25 12 2.25s9.75 4.365 9.75 9.75-4.365 9.75-9.75 9.75z" />
  </Svg>
);

const GoLiveButton = ({ goLive, event, contentBeforeReady, style }) => {
  const [goliveloading, setgoliveloading] = useState(false);

  const {
    stream_key,
    is_published,
    is_test,
    event_window_start_datetime_utc,
    event_window_end_datetime_utc,
    event_status,
  } = event;

  const now = useNow();

  // const streamingAvailable =
  //   is_published &&
  //   now.isBetween(
  //     event_window_start_datetime_utc,
  //     event_window_end_datetime_utc
  //   ) &&
  //   event_status !== "live";

  // const isBefore = now.isBefore(event_window_start_datetime_utc);
  const isAfter = now.isAfter(event_window_end_datetime_utc);

  const testExpired = is_test && isAfter;
  const eventNeverStreamed =
    isAfter && is_published && !is_test && event_status === "upcoming";

  const liveButtonTitle = is_test
    ? "Test Live"
    : event_status === "upcoming"
    ? "Go Live"
    : "Continue";

  const buttonAction = async () => {
    // setgoliveloading(true);
    await goLive();
    // setgoliveloading(false);
  };

  return (
    <>
      {is_published && (
        <Button
          rounded
          dark
          block
          disabled={!!goliveloading}
          style={[
            {
              // marginLeft: 9,
              // marginRight: 9,
              paddingLeft: 41,
              paddingRight: 41,
              marginBottom: 10,
              height: 62,
            },
            !!goliveloading && { backgroundColor: "#b5b5b5" },
            style,
          ]}
          primary
          onPress={buttonAction}
        >
          {goliveloading && <ClockIcon fill="white" />}
          {/* {!goliveloading && <CameraIcon fill="white" />} */}
          {!goliveloading && (
            <Ionicons
              name="videocam"
              size={24}
              color="white"
              style={{ marginRight: 5 }}
            />
          )}
          <Text style={{ padding: 0, paddingRight: 9, fontSize: 18 }}>
            {liveButtonTitle}
          </Text>
        </Button>
      )}

      {testExpired && (
        <Text
          style={{
            alignSelf: "center",
            textAlign: "center",
          }}
        >
          Test event expired. Please create a new one.
        </Text>
      )}

      {eventNeverStreamed && (
        <Text
          style={{
            alignSelf: "center",
            textAlign: "center",
          }}
        >
          This event has not gone Live. Please reschedule in the settings.
        </Text>
      )}
    </>
  );
};

export default GoLiveButton;
