import * as Clipboard from "expo-clipboard";
import { Formik, Form } from "formik";
import { Left, ListItem, Right, Text, Button, Icon, Toast } from "native-base";
import React, { useState } from "react";
import { Platform } from "react-native";
import { updateEventEmbedOptions } from "../http/events";
import { EmbedSchema } from "../screens/Schemas";
import AppInput from "./AppInput";
import AppRadio from "./AppRadio";
import AppSwitch from "./AppSwitch";
import AutoSave from "./AutoSave";
import analytics from "../../analytics";
import { eventLivePageBase } from "../globalUtils";

if (typeof URLSearchParams.prototype.set !== "function") {
  URLSearchParams.prototype.set = function (name, value) {
    let params = Array.from(this.entries());
    let found = false;
    params = params.map(([n, v]) => {
      if (n === name) {
        found = true;
        return [n, value];
      }
      return [n, v];
    });
    if (!found) {
      params.push([name, value]);
    }
    return new URLSearchParams(params);
  };
}

const EventEmbedForm = ({ event }) => {
  const [embedStyle, setEmbedStyle] = useState(`embed`);
  const [autoplay, setAutoplay] = useState(true);
  const [liveOnly, setLiveOnly] = useState(false);
  const [reminders, setReminders] = useState(true);

  const allowAutoplay = autoplay ? `allow="autoplay"` : ``;

  const params = new URLSearchParams({
    autoplay,
  });

  if (!reminders) {
    params.set("rsvp_off", true);
  }

  if (liveOnly) {
    params.set("liveonly", true);
  }

  // Todo: support alias
  const embedCodes = {
    embedBare: `<div style="padding-bottom:56.25%;position:relative;margin:0 auto;"><iframe src="${eventLivePageBase}${
      event.broadcaster
    }/${
      event.slug
    }/embedBare?${params.toString()}" style="width:100%;height:100%;position:absolute;left:0px;top:0px;" width="100%" height="100%" allowfullscreen ${allowAutoplay} allowtransparency="true" frameborder="0"></iframe></div><a style="font-size:10px;font-family:sans-serif;color:#999;text-decoration:none;" href="https://www.eventlive.pro/">Powered by EventLive</a>`,
    embed: `<div class="eventlive-embed"><iframe src="${eventLivePageBase}${
      event.broadcaster
    }/${
      event.slug
    }/embed?${params.toString()}" width="100%" height="100%" allowfullscreen allow="autoplay" allowtransparency="true" frameborder="0"></iframe></div><a style="font-size:10px;font-family:sans-serif;color:#999;text-decoration:none;" href="https://www.eventlive.pro/">Powered by EventLive</a><style scoped> .eventlive-embed{padding-bottom: 56.25%; position: relative; margin: 0 auto;}.eventlive-embed iframe{width: 100%; height: 100%; position: absolute; left: 0px; top: 0px;}@media only screen and (max-width: 576px){.eventlive-embed{min-height: 630px; padding-bottom: 0;}.eventlive-embed iframe{position: static; min-height: 630px;}}</style>`,
  };

  const embedCode = embedCodes[embedStyle];

  return (
    <Formik
      initialValues={{
        embedlink: event.embedlink || "",
      }}
      onSubmit={async (values, actions) => {
        actions.setSubmitting(true);
        try {
          await updateEventEmbedOptions({
            slug: event.slug,
            details: values,
          });
          actions.setSubmitting(false);
        } catch (err) {
          console.error(err);
          actions.setSubmitting(false);
        }
      }}
      validationSchema={EmbedSchema}
    >
      {(f) => (
        <>
          <AutoSave {...f} />
          <ListItem style={{ flexDirection: "column", flex: 1, marginLeft: 0 }}>
            <AppRadio
              selected={embedStyle === "embed"}
              onSelect={() => setEmbedStyle("embed")}
              label="Full Embed"
            />
            <AppRadio
              selected={embedStyle === "embedBare"}
              onSelect={() => setEmbedStyle("embedBare")}
              label="Just Video"
            />
          </ListItem>
          <ListItem style={{ marginLeft: 0 }}>
            <Left>
              <Text>Autoplay</Text>
            </Left>
            <Right>
              <AppSwitch
                isOn={autoplay}
                onToggle={(value) => setAutoplay(value)}
              />
            </Right>
          </ListItem>

          <ListItem style={{ marginLeft: 0 }}>
            <Left>
              <Text>Reminder button</Text>
            </Left>
            <Right>
              <AppSwitch
                isOn={reminders}
                onToggle={(value) => setReminders(value)}
              />
            </Right>
          </ListItem>

          <ListItem style={{ marginLeft: 0 }}>
            <Left>
              <Text>Live Only (no replay, no rewind)</Text>
            </Left>
            <Right>
              <AppSwitch
                isOn={liveOnly}
                onToggle={(value) => setLiveOnly(value)}
              />
            </Right>
          </ListItem>

          {(reminders || f.values.embedlink) && (
            <AppInput
              onChangeText={f.handleChange("embedlink")}
              style={{ marginTop: 10 }}
              onBlur={f.handleBlur("embedlink")}
              value={f.values.embedlink}
              placeholder="Embed webpage URL (Optional, for links in reminders)"
              onSubmitOnEnter={() => f.handleSubmit()}
              errorMessage={
                f.errors.embedlink && f.touched.embedlink
                  ? f.errors.embedlink
                  : null
              }
            />
          )}

          <AppInput
            value={embedCode}
            disabled
            inputStyle={{
              backgroundColor: "#ffffff",
              borderColor: "#ffffff",
              paddingLeft: 0,
            }}
            style={{
              backgroundColor: "#ffffff",
              borderColor: "#ffffff",
              marginTop: 32,
            }}
            rightComponent={
              <Button
                dark
                onPress={() => {
                  Clipboard.setString(embedCode);
                  analytics.track("Copy Embed", {
                    liveOnly,
                    embedStyle,
                    autoplay,
                    ...f.values,
                  });
                  Toast.show({
                    text: "Copied!",
                    position: "top",
                    type: "success",
                  });
                }}
              >
                <Icon
                  active
                  name={"copy-outline"}
                  style={{ color: "#FFFFFF" }}
                />
                <Text>Copy Embed Code</Text>
              </Button>
            }
            // iconRightName={"copy-outline"}
            // onIconRightAction={() => Clipboard.setString(embedCode)}
          />

          {Platform.OS === "web" && (
            <>
              <Text style={{ marginTop: 40, marginBottom: 20 }}>
                Embed Preview
              </Text>
              <div dangerouslySetInnerHTML={{ __html: embedCode }}></div>
            </>
          )}
        </>
      )}
    </Formik>
  );
};

export default EventEmbedForm;
