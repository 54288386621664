import React from "react";
import { Pressable, StyleSheet, View, useWindowDimensions } from "react-native";
import { Toast, Root } from "native-base";
import { isWeb, minDesktopWidth } from "../globalUtils";
import Modal from "./Modal";
import AppInput from "./AppInput";
import useClipboardCallback from "../hooks/useClipboardCallback";

const externalOpen = (URL) => window.open(URL, "_blank", "noopener");

const iconList = {
  facebook: {
    path: (
      <path d="M24 12a12 12 0 10-13.9 11.9v-8.4h-3V12h3V9.4c0-3 1.8-4.7 4.6-4.7l2.6.2v3h-1.5c-1.5 0-2 .9-2 1.8V12h3.4l-.5 3.5h-2.8v8.4A12 12 0 0024 12z" />
    ),
    color: "#0076FB",
    e: (l) => externalOpen(`https://www.facebook.com/sharer/sharer.php?u=${l}`),
  },
  twitter: {
    path: (
      <path d="M24 4.6a10 10 0 01-2.9.7 5 5 0 002.2-2.7c-1 .6-2 1-3.1 1.2a5 5 0 00-8.4 4.5A14 14 0 011.6 3.2 4.8 4.8 0 001 5.6a5 5 0 002.2 4.1 4.9 4.9 0 01-2.3-.6A5 5 0 005 14a5 5 0 01-2.2 0 5 5 0 004.6 3.5 9.9 9.9 0 01-6.1 2.1H0a14 14 0 007.6 2.2c9 0 14-7.5 14-14V7A10 10 0 0024 4.6z" />
    ),
    color: "#1DA1F2",
    e: (l, t) =>
      externalOpen(`https://twitter.com/intent/tweet?text=${t}&url=${l}`),
  },
  whatsapp: {
    path: (
      <path d="M17.5 14.4l-2-1c-.3 0-.5-.1-.7.2l-1 1.1c-.1.2-.3.3-.6.1s-1.3-.5-2.4-1.5a9 9 0 01-1.7-2c-.1-.3 0-.5.2-.6l.4-.6c.2-.1.2-.3.3-.5v-.5L9 7c-.2-.6-.4-.5-.6-.5h-.6c-.2 0-.5 0-.8.4-.2.3-1 1-1 2.5s1 2.8 1.2 3c.2.2 2.1 3.2 5.1 4.5l1.7.6a4 4 0 001.9.2c.5-.1 1.7-.8 2-1.5.2-.6.2-1.2.1-1.4l-.5-.3M12 21.8a9.9 9.9 0 01-5-1.4l-.4-.2-3.7 1 1-3.7-.2-.3a9.9 9.9 0 01-1.5-5.3 9.9 9.9 0 0116.8-7 9.8 9.8 0 013 7 9.9 9.9 0 01-10 9.9m8.4-18.3A11.8 11.8 0 0012.1 0 12 12 0 001.8 17.8L0 24l6.4-1.6a11.9 11.9 0 005.6 1.4 12 12 0 0012-11.9 11.8 11.8 0 00-3.5-8.4z" />
    ),
    color: "#25D366",
    e: (l, t) => externalOpen(`https://api.whatsapp.com/send?text=${t} ${l}`),
  },
  mail: {
    path: (
      <path d="M20 4H4a2 2 0 00-2 2v12c0 1.1.9 2 2 2h16a2 2 0 002-2V6a2 2 0 00-2-2zm0 4l-8 5-8-5V6l8 5 8-5v2z" />
    ),
    color: "#E53E3E",
    e: (l, t) => externalOpen(`mailto:?body=${l}&subject=${t}`),
  },
};

const Icon = ({ name, data, onClose, onClick }) => {
  const { path, viewBox = "0 0 24 24", color, e } = iconList[name];

  const handleOnButtonClicked = () => {
    onClick && onClick(); // callback
    e(encodeURIComponent(data.url), data.text, data.title);
    onClose();
  };

  return (
    <Pressable
      onPress={handleOnButtonClicked}
      style={
        isWeb
          ? {
              cursor: "pointer",
              background: "#1A78F6",
              flex: 1,
              borderRadius: "25%",
              background: color,
            }
          : { background: color }
      }
    >
      <svg fill="white" viewBox={viewBox} style={{ margin: 15 }}>
        {path}
      </svg>
    </Pressable>
  );
};

const SocialIconsModal = ({ visible, onRequestClose, url, title, text }) => {
  const styles = StyleSheet.create({
    iconsViewContainer: {
      width: "100%",
      alignItems: "center",
      alignContent: "center",
      justifyContent: "center",
      marginTop: 30,
      display: "flex",
      flexDirection: "row",
    },
  });

  const clipBoardCallback = useClipboardCallback();
  const { width: responsiveWidth } = useWindowDimensions();
  const isMobileUX = minDesktopWidth > responsiveWidth;

  return (
    <Modal visible={visible} onRequestClose={onRequestClose} maxHeight={420}>
      <Root>
        <View>
          <AppInput
            value={url}
            disabled={true}
            labelStyle={{ textAlign: "right" }}
            iconRightName={"copy-outline"}
            onIconRightAction={() => clipBoardCallback(url)}
          />
        </View>
        <View style={styles.iconsViewContainer}>
          {["mail", "facebook", "whatsapp", "twitter"].map((icon) => (
            <Icon
              key={icon}
              name={icon}
              data={{ url, title, text }}
              onClose={onRequestClose}
            />
          ))}
        </View>
      </Root>
    </Modal>
  );
};

export default SocialIconsModal;
