import * as Linking from "expo-linking";
import { Formik } from "formik";
import { Toast, Text, Button } from "native-base";
import React, { useEffect, useState } from "react";
import { updateEventStripeDetails } from "../http/events";
import * as Clipboard from "expo-clipboard";
import { View, Platform, useWindowDimensions } from "react-native";
import { useNavigation } from "@react-navigation/native";
import { StripeSchema } from "../screens/Schemas";
import AppActivityIndicator from "@/components/shared/AppActivityIndicator";
import { isWeb, minDesktopWidth, eventLivePageBase } from "../globalUtils";
import AutoSave from "./AutoSave";
import AppPicker from "@/components/shared/AppPicker";
import AppTitle from "@/components/shared/AppTitle";
import analytics from "@/analytics";
import { Ionicons } from "@expo/vector-icons";
import Bullet from "./Bullet";
import useClipboardCallback from "../hooks/useClipboardCallback";

const Link = ({ onPress, children, style }) => (
  <Text style={{ color: "#f4553b", ...style }} onPress={onPress}>
    {children}
  </Text>
);

const SellTickets = ({ event }) => {
  const [stripeDetails, setStripeDetails] = useState(null);
  const [loading, setloading] = useState(false);
  const clipBoardCallback = useClipboardCallback();
  const { width: responsiveWidth } = useWindowDimensions();
  const isMobileUX = minDesktopWidth > responsiveWidth;

  const openURL = (url) => {
    if (Platform.OS === "web") {
      window.open(url, "_blank");
    } else {
      Linking.openURL(url);
    }
  };

  const connectStripe = () => {
    analytics.track("Connect Stripe");
    openURL(
      `${eventLivePageBase}api/stripe/payperviewredirect?broadcaster=${event.broadcaster}&slug=${event.slug}`
    );
  };

  const navigation = useNavigation();

  const {
    stripe_price_id,
    stripe_price_id_test,
    stripe_connected_account_id,
    slug,
    broadcaster,
  } = event;

  const openStripe = () => {
    openURL(
      `https://dashboard.stripe.com/b/${stripe_connected_account_id}/products`
    );
  };

  const addProduct = () => {
    openURL(
      `https://dashboard.stripe.com/b/${stripe_connected_account_id}/products?active=true&create=product&source=product_list`
    );
  };

  const fetchStripeDetails = async () => {
    if (stripe_connected_account_id) {
      setloading(true);
      try {
        const response = await fetch(
          `${eventLivePageBase}api/stripe/info?broadcaster=${broadcaster}&slug=${slug}`
        );

        const data = await response.json();
        setStripeDetails(data);
        setloading(false);
      } catch (err) {
        setloading(false);
      }
    }
  };

  useEffect(() => {
    fetchStripeDetails();
  }, [stripe_connected_account_id, broadcaster, slug]);

  if (!event.title) {
    return <AppActivityIndicator />;
  }

  return (
    <View style={{ margin: 20 }}>
      {!stripe_connected_account_id && (
        <>
          <Text style={{ marginBottom: 20 }}>
            EventLive Pay-per-view Live streaming powered by Stripe.
          </Text>
          <Bullet>Your Stripe account, Your branding, Your price</Bullet>
          <Bullet>
            EventLive fee is just $1.50 per ticket sold (Limited Time Offer)
          </Bullet>
          <Bullet>Use coupons and other Stripe features</Bullet>
          <Button primary block onPress={connectStripe}>
            <Text>Connect or Create Stripe Account</Text>
          </Button>
          <Link
            onPress={() =>
              clipBoardCallback(
                `${eventLivePageBase}api/stripe/payperviewredirect?broadcaster=${event.broadcaster}&slug=${event.slug}`
              )
            }
            style={{ fontWeight: "bold", textAlign: "center", marginTop: 20 }}
          >
            <Ionicons
              name="copy-outline"
              size={16}
              color="#F84123"
              style={{ marginRight: 5 }}
            />
            Copy Link to Connect Stripe Account
          </Link>
          <Text style={{ marginTop: 20 }}>
            * Please note that Stripe fees, refunds, and chargebacks are
            responsibility of connected account holder
          </Text>
          <Text style={{ marginTop: 20 }}>
            * Reach out in the support chat if you need assistance with
            configuring your event
          </Text>
        </>
      )}
      {stripe_connected_account_id && (
        <View>
          <AppTitle
            text={`Stripe Account Connected`}
            style={{ textAlign: "left", marginBottom: 20 }}
          />

          {stripeDetails && (
            <>
              <Text
                style={{
                  marginHorizontal: 0,
                  marginVertical: 10,
                  fontWeight: "bold",
                }}
              >
                Stripe Account: {stripeDetails.accountDetails.business_name}{" "}
                {stripeDetails.accountDetails.email}{" "}
                <Link onPress={fetchStripeDetails}>🔄</Link>{" "}
                <Link onPress={openStripe}>Open Dashboard</Link>
              </Text>
            </>
          )}
          {(!stripeDetails || loading) && <AppActivityIndicator />}
          {stripeDetails &&
            (!stripeDetails?.prices?.length ||
              !stripeDetails?.prices?.filter((p) => !!p.active)?.length) && (
              <>
                <Bullet>
                  Next Step:{" "}
                  <Link onPress={addProduct} style={{ fontWeight: "bold" }}>
                    Create a product in Stripe
                  </Link>
                  . Choose "One-Off" and "Flat Rate" or "Customer Chooses Price"
                  for pricing model. Add as many currencies as you want.
                </Bullet>
              </>
            )}
          <Text style={{ marginVertical: 10 }}>
            Choose the price for this event:
          </Text>

          <Formik
            initialValues={{
              stripe_price_id: stripe_price_id || "",
              stripe_price_id_test: stripe_price_id_test || "",
              stripe_connected_account_id,
            }}
            onSubmit={async (values, actions) => {
              actions.setSubmitting(true);
              try {
                await updateEventStripeDetails(slug, values);
                actions.setSubmitting(false);
              } catch (err) {
                console.error(err);
                actions.setSubmitting(false);
              }
            }}
            validationSchema={StripeSchema}
          >
            {(f) => (
              <View style={{ marginBottom: 40 }}>
                <AutoSave {...f} />
                {stripeDetails && (
                  <AppPicker
                    label="Stripe Price"
                    placeholder="Not Selected"
                    values={[
                      { name: "None", value: "" },
                      ...stripeDetails.prices.map((p) => ({
                        name: p.description,
                        value: p.id,
                      })),
                    ]}
                    selectedValue={f.values.stripe_price_id}
                    onValueChange={f.handleChange("stripe_price_id")}
                  />
                )}
              </View>
            )}
          </Formik>
          <AppTitle
            text={"Checklist for ticket sales"}
            style={{ textAlign: "left", marginBottom: 20 }}
          />
          <Bullet isChecked={event.event_privacy === "invite_only"}>
            Turn on "Invite Only" setting in{" "}
            <Link
              onPress={() =>
                navigation.navigate("EventPrivacy", { event, slug })
              }
            >
              Event Privacy
            </Link>
            &nbsp;to enable Pay-per-View
          </Bullet>

          <Bullet>
            Upload your logo in{" "}
            <Link onPress={() => navigation.navigate("UpdateEmail")}>
              Profile Settings
            </Link>
          </Bullet>

          <Bullet>
            (Optional) Invite viewers to attend for free in{" "}
            <Link onPress={() => navigation.navigate("GuestList")}>
              Guest List & Invite
            </Link>
          </Bullet>

          <AppTitle
            text={"Stripe settings to enable"}
            style={{ textAlign: "left", marginBottom: 20 }}
          />
          <Bullet>
            Under Business Settings &gt; Customer emails:{" "}
            <Link
              onPress={() =>
                openURL(
                  "https://dashboard.stripe.com/b/${stripe_connected_account_id}/settings/emails"
                )
              }
            >
              Email customers about Successful payments
            </Link>
          </Bullet>
          <Bullet>
            Under Business Settings &gt; Branding:{" "}
            <Link
              onPress={() =>
                openURL(
                  "https://dashboard.stripe.com/b/${stripe_connected_account_id}/settings/branding"
                )
              }
            >
              Choose your brand colors and upload your logo
            </Link>
          </Bullet>
        </View>
      )}
    </View>
  );
};

export default SellTickets;
