// import "react-native-gesture-handler";

import * as Font from "expo-font";
// import * as Updates from "expo-updates";
import React, { useEffect, useState } from "react";
// import * as Linking from "expo-linking";

import { Alert, Platform } from "react-native";
import DropdownAlert from "react-native-dropdownalert";
import { withIAPContext } from "react-native-iap";
import { SafeAreaProvider } from "react-native-safe-area-context";

import { Amplify, Auth } from "aws-amplify";
import { Ionicons } from "@expo/vector-icons";

import AppConfig from "@/AppConfig";
import AppRoot from "@/components/AppRouting";
import { ProvideAuth } from "@/components/AuthContext";
import { ProvideBuy } from "@/components/BuyContext";
import { ProvideShare } from "@/components/hooks/useShare";
import { IsAppleUniverse } from "@/components/globalUtils";
import ChatWidget from "@/components/shared/ChatWidget";
import sentry from "@/sentry";
import Feedback from "@/components/shared/Feedback";

const environment = process.env.NODE_ENV;

// console.log(environment);

const wrapper = Platform.OS === "web" ? (a) => a : withIAPContext;
Amplify.configure({
  Auth: {
    identityPoolId: AppConfig.REACT_APP_IDENTITY_POOL_ID,
    region: AppConfig.REACT_APP_REGION,
    userPoolId: AppConfig.REACT_APP_USERPOOL_ID,
    userPoolWebClientId: AppConfig.REACT_APP_CLIENT_ID,
    mandatorySignIn: false,
    aws_federation_target: "COGNITO_USER_POOLS",
    federatedTarget: "COGNITO_USER_POOLS",
    authenticationFlowType: "CUSTOM_AUTH",
    oauth: {
      domain: AppConfig.REACT_APP_COGNITO_DOMAIN,
      scope: ["public_profile", "email"],
      redirectSignIn: `eventlive://signIn`, // Linking.makeUrl('signIn'),
      redirectSignOut: `eventlive://signOut`, //Linking.makeUrl('signOut'),
      responseType: "code", //token
    },
    // cookieStorage: {
    //   domain:
    //     environment === "production"
    //       ? "eventlive.pro"
    //       : window.location.hostname,
    //   secure: window.location.hostname !== "localhost",
    //   path: "/",
    //   expires: 365,
    // },
  },
  aws_appsync_graphqlEndpoint: AppConfig.REACT_APP_GRAPHQL_ENDPOINT,
  aws_appsync_region: AppConfig.REACT_APP_REGION,
  aws_appsync_authenticationType: "AWS_IAM",
  Storage: {
    customPrefix: {
      public: "",
    },
  },

  graphql_headers: async () => {
    try {
      const session = await Auth.currentSession();
      const token = session.accessToken.jwtToken;
      // (await Auth.currentSession()).getIdToken().getJwtToken();
      return {
        // Authorization: idtoken,
        JWT: token,
      };
    } catch (e) {
      // console.error("Auth.currentSession(); ", e);
      return {};
    }
  },
});

console.reportErrorsAsExceptions = false;

function App() {
  const [ready, setReady] = useState(false);
  const navigationRef = React.useRef(null);

  useEffect(() => {
    (async function () {
      await Font.loadAsync(
        IsAppleUniverse
          ? { ...Ionicons.font }
          : {
              Nunito_semibold: require("./assets/fonts/Nunito/Nunito-SemiBold.ttf"),
              Nunito_bold: require("./assets/fonts/Nunito/Nunito-ExtraBold.ttf"),
              Nunito_regular: require("./assets/fonts/Nunito/Nunito-Regular.ttf"),
              ...Ionicons.font,
            }
      );

      // try {
      //   const update = await Updates.checkForUpdateAsync();
      //   if (update.isAvailable) {
      //     await Updates.fetchUpdateAsync();
      //     // ... notify user of update ...
      //     Alert.alert("Update Available", "Click OK to update EventLive", [
      //       {
      //         text: "OK",
      //         onPress: () => {
      //           Updates.reloadAsync();
      //         },
      //         style: "default",
      //       },
      //     ]);
      //   }
      // } catch (e) {
      //   // handle or log error
      // }
    })();

    setReady(true);
  }, []);

  return (
    ready && (
      <SafeAreaProvider>
        <ProvideAuth navigation={navigationRef.current}>
          <ProvideBuy>
            <ProvideShare>
              <AppRoot ref={navigationRef} />
            </ProvideShare>
            <Feedback />
            <ChatWidget />
          </ProvideBuy>
        </ProvideAuth>
        {/* Magical dropdown global ref */}
        <DropdownAlert ref={(ref) => (global.dropDownAlertRef = ref)} />
      </SafeAreaProvider>
    )
  );
}

export default wrapper(App);
