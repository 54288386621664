// @flow

export default () => {
  const tabTheme = {
    flex: 1,
    backgroundColor: "#FFF",
  };

  return tabTheme;
};
