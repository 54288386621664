import moment from "moment-timezone";
import * as WebBrowser from "expo-web-browser";
import React, { useState } from "react";
import { Pressable, StyleSheet, View, useWindowDimensions } from "react-native";
import { Toast, Icon, Tabs, Tab, TabHeading, Text, Button } from "native-base";
import { Ionicons } from "@expo/vector-icons";
import { isWeb, minDesktopWidth, makeUrl } from "../globalUtils";
import AppInput from "./AppInput";
import Link from "./Link";
import FormSubtitle from "./FormSubtitle";
import { resetEvent } from "../http/events";
import useClipboardCallback from "@/components/hooks/useClipboardCallback";
import { useNavigation } from "@react-navigation/native";
import useNow from "@/components/hooks/useNow";

const openLink = async (url) => {
  await WebBrowser.openBrowserAsync(url);
};

export const UrlToWatch = ({ title, url }) => {
  const clipBoardCallback = useClipboardCallback();

  return (
    <>
      <FormSubtitle>{title}</FormSubtitle>
      <AppInput
        value={url}
        disabled={true}
        labelStyle={{ textAlign: "right" }}
        rightComponent={
          <>
            <Pressable
              style={({ pressed }) => [{ opacity: pressed ? 0.3 : 1.0 }]}
              onPress={() => clipBoardCallback(url)}
            >
              <Icon active name={"copy-outline"} style={{ color: "#8B8B8D" }} />
            </Pressable>
            <Pressable
              style={({ pressed }) => [{ opacity: pressed ? 0.3 : 1.0 }]}
              onPress={() => {
                openLink(url);
              }}
            >
              <Icon active name={"open-outline"} style={{ color: "#8B8B8D" }} />
            </Pressable>
          </>
        }
      />
    </>
  );
};

const RtmpForm = ({ event, test_event, custom_domain }) => {
  const {
    is_published,
    stream_key,
    broadcaster,
    event_status,
    slug,
    is_test,
    displayStyle = "server_key",
    event_window_start_datetime_utc,
  } = event;
  const clipBoardCallback = useClipboardCallback();

  const url = makeUrl(event, custom_domain);

  // const rtmpServer = "rtmp://go.eventlive.pro/live";
  const rtmpsServer = "rtmps://go.eventlive.pro:443/live";
  const server = rtmpsServer;
  const serverFull = `${server}/${stream_key}`;

  // FOR test events event details are in the event object
  const test_stream_key = is_test ? stream_key : test_event?.stream_key;
  const test_url = is_test
    ? `https://evt.live/${broadcaster}/${slug}`
    : `https://evt.live/${test_event?.broadcaster}/${test_event?.slug}`;
  const serverFullTest = `${server}/${test_stream_key}`;
  const navigation = useNavigation();

  const now = useNow();

  const isBefore = now.isBefore(event_window_start_datetime_utc);

  const resetVideo = () => {
    resetEvent(slug);
  };

  return (
    <View>
      <Tabs
        tabBarUnderlineStyle={{
          color: "#000",
          borderBottomColor: "#000",
          borderBottomWidth: 1,
        }}
        tabContainerStyle={{ width: "100%" }}
      >
        {!is_test && (
          <Tab
            heading={
              <TabHeading>
                <Text>Live Event</Text>
              </TabHeading>
            }
            activeTextStyle={{ color: "#000" }}
          >
            {!is_published && !is_test && (
              <View
                style={{
                  position: "absolute",
                  top: 0,
                  right: 0,
                  left: 0,
                  bottom: 0,
                  width: "100%",
                  height: "100%",
                  zIndex: 1000,
                  backgroundColor: "rgba(0,0,0,0.5)",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <View style={{ alignSelf: "center" }}>
                  <Button
                    onPress={() => {
                      navigation.navigate("PayWall", { event, slug });
                    }}
                  >
                    <Text>Unlock RTMP access</Text>
                  </Button>
                </View>
              </View>
            )}

            {displayStyle === "server_key" && (
              <>
                <FormSubtitle>RTMP Server</FormSubtitle>
                <AppInput
                  value={server}
                  disabled={true}
                  labelStyle={{ textAlign: "right" }}
                  iconRightName={"copy-outline"}
                  onIconRightAction={() => clipBoardCallback(server)}
                />

                <>
                  <FormSubtitle>Your Event's Private Stream Key</FormSubtitle>
                  <AppInput
                    value={!is_published && !is_test ? "*****" : stream_key}
                    disabled={true}
                    labelStyle={{ textAlign: "right" }}
                    iconRightName={"copy-outline"}
                    onIconRightAction={() => clipBoardCallback(stream_key)}
                  />
                  {isBefore && (
                    <View
                      style={{ flexDirection: "row", alignItems: "center" }}
                    >
                      <Ionicons
                        name="time-outline"
                        size={24}
                        color="#000"
                        style={{ marginRight: 8 }}
                      />
                      <Text>
                        This stream key will be available on{" "}
                        {moment(event_window_start_datetime_utc).format("LL")}{" "}
                        at{" "}
                        {moment(event_window_start_datetime_utc).format("LT")}{" "}
                        {moment.tz(moment.tz.guess()).zoneAbbr()} (1 hour before
                        the event starts). You can use test event RTMPs in the
                        meantime.
                      </Text>
                    </View>
                  )}
                </>
              </>
            )}

            {displayStyle === "url" && (
              <>
                <FormSubtitle>RTMP URL</FormSubtitle>
                <AppInput
                  value={serverFull}
                  disabled={true}
                  labelStyle={{ textAlign: "right" }}
                  iconRightName={"copy-outline"}
                  onIconRightAction={() => clipBoardCallback(serverFull)}
                />
                {isBefore && (
                  <View style={{ flexDirection: "row", alignItems: "center" }}>
                    <Ionicons
                      name="time-outline"
                      size={24}
                      color="#000"
                      style={{ marginRight: 8 }}
                    />
                    <Text>
                      This URL will be available on{" "}
                      {moment(event_window_start_datetime_utc).format("LL")} at{" "}
                      {moment(event_window_start_datetime_utc).format("LT")}{" "}
                      {moment.tz(moment.tz.guess()).zoneAbbr()} (1 hour before
                      the event starts). You can use test event URL in the
                      meantime.
                    </Text>
                  </View>
                )}
              </>
            )}

            <UrlToWatch title="Link to watch your event" url={url} />
          </Tab>
        )}
        <Tab
          heading="Free Test Event"
          activeTabStyle={{ borderBottomColor: "#000" }}
          activeTextStyle={{ color: "#000" }}
        >
          {displayStyle === "server_key" && (
            <>
              <FormSubtitle>RTMP Server</FormSubtitle>
              <AppInput
                value={server}
                disabled={true}
                labelStyle={{ textAlign: "right" }}
                iconRightName={"copy-outline"}
                onIconRightAction={() => clipBoardCallback(server)}
              />

              <FormSubtitle>Your Test Event's Stream Key</FormSubtitle>
              <AppInput
                value={test_stream_key}
                disabled={true}
                labelStyle={{ textAlign: "right" }}
                iconRightName={"copy-outline"}
                onIconRightAction={() => clipBoardCallback(test_stream_key)}
              />
            </>
          )}

          {displayStyle === "url" && (
            <>
              <FormSubtitle>RTMP URL</FormSubtitle>
              <AppInput
                value={serverFullTest}
                disabled={true}
                labelStyle={{ textAlign: "right" }}
                iconRightName={"copy-outline"}
                onIconRightAction={() => clipBoardCallback(serverFullTest)}
              />
            </>
          )}

          <UrlToWatch title="Link to watch your test event" url={test_url} />

          <Text style={{ paddingVertical: 10 }}>
            You can test live as many times as you need.
          </Text>
        </Tab>
      </Tabs>

      {event_status === "past" && is_test && (
        <View>
          <Link onPress={resetVideo}>
            <Ionicons name="ios-trash" size={14} color="#F84123" /> Reset Test
            Video
          </Link>
        </View>
      )}
    </View>
  );
};

export default RtmpForm;
