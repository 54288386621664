import * as Sharing from "expo-sharing";
import { useState } from "react";
import { isWindows } from "react-device-detect";
import { Platform, Share } from "react-native";
import * as Clipboard from "expo-clipboard";

import { isWeb } from "../globalUtils";
import analytics from "../../analytics";
import SocialIconsModal from "../shared/SocialIconsModal";

export const useSharePress = (title, url) => {
  const [socialIconsVisible, setsocialIconsVisible] = useState(false);
  const shareSubject = `You're invited to watch ${title}`;

  const sharePress = async () => {
    // Analytics track
    analytics.track("Share Link", {
      url,
      title,
    });

    if (isWeb) {
      if (navigator.share) {
        try {
          await navigator.share({
            title: shareSubject,
            url: url,
          });
        } catch (error) {
          console.error("Error sharing:", error);
        }
      } else {
        await Clipboard.setStringAsync(url);
        alert("Link copied to clipboard!");
      }
    } else {
      try {
        if (Platform.OS === "ios") {
          await Share.share(
            {
              title: url,
              url: url,
            },
            {
              subject: shareSubject,
              excludedActivityTypes: [
                "com.apple.mobilenotes.SharingExtension",
                "com.apple.reminders.RemindersEditorExtension",
                "UIActivityTypeAddToReadingList",
              ],
            }
          );
        } else {
          // Android
          await Share.share(
            {
              title: shareSubject,
              message: url,
            },
            {
              dialogTitle: "Share your event link",
            }
          );
        }
      } catch (error) {
        console.error("Error sharing:", error);
      }
    }
  };

  const ShareModal = () =>
    Platform.OS === "web" ? (
      <SocialIconsModal
        visible={socialIconsVisible}
        url={url}
        title={title}
        text={shareSubject}
        onRequestClose={() => {
          setsocialIconsVisible(false);
        }}
      />
    ) : null;

  return { sharePress, ShareModal };
};

export default useSharePress;
