import * as Clipboard from "expo-clipboard";
import { useClipboard } from "use-clipboard-copy";

import { Toast } from "native-base";
import { useCallback } from "react";

import { isWeb } from "@/components/globalUtils";

const useClipboardCallback = () => {
  const clipboard = useClipboard({
    copiedTimeout: 1000,
  });

  return useCallback(
    (value) => {
      if (isWeb) {
        clipboard.copy(value);
      } else {
        Clipboard.setString(value);
      }
      Toast.show({
        text: "Copied",
        position: "bottom",
        type: "success",
      });
    },
    [clipboard]
  );
};

export default useClipboardCallback;
