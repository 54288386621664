import * as DocumentPicker from "expo-document-picker";
import { Button, Text, Toast, Icon } from "native-base";
import React, { useState } from "react";
import { Platform } from "react-native";

import { Storage } from "aws-amplify";

import { StyleConstants } from "@/components/globalStyle";
import { isWeb } from "@/components/globalUtils";
import AppActivityIndicator from "@/components/shared/AppActivityIndicator";

const UploadFile = ({
  type = "image/*",
  path,
  bucket,
  disclaimer,
  onStart,
  onComplete,
  onCancel,
  bordered,
  dark,
  buttonTitle = "Upload File",
}) => {
  const [loading, setloading] = useState(false);

  // No cancel on web: https://github.com/expo/expo/issues/8477
  const selectDocument = async () => {
    try {
      if (onStart) {
        onStart();
      }
      console.log("opening picker");
      const r = await DocumentPicker.getDocumentAsync({
        type,
        multiple: false,
      });

      const { assets, canceled } = r;

      setloading(true);

      if (canceled) {
        setloading(false);
        if (onCancel) onCancel();
        return;
      }

      const { name, file, uri, mimeType } = assets?.[0];

      let content;
      let contentType = "";

      if (isWeb) {
        content = file;
        contentType = file?.type;
      } else {
        const finaluri = Platform.os === "ios" ? uri : `file://${uri}`;
        const response = await fetch(finaluri);
        content = await response.blob();
      }

      const { key } = await Storage.put(`${path}/${name}`, content, {
        bucket,
        region: "us-east-1",
        acl: "public-read",
        cacheControl: "no-cache",
        contentType,
        // progressCallback: (progress) => {
        //   console.log(progress);
        // },
      });

      Toast.show({
        text: "File Uploaded",
        position: "bottom",
        type: "success",
      });

      if (onComplete) {
        await onComplete({
          name,
          file,
          uri,
          type,
          url: `https://${bucket}.s3.amazonaws.com/${path}/${encodeURIComponent(
            name
          )}`,
        });
      }
      setloading(false);
    } catch (err) {
      setloading(false);
      if (onCancel) onCancel();
      console.error(err);

      Toast.show({
        text: String(err),
        position: "top",
        type: "danger",
        duration: 5000,
      });
    }
  };

  return (
    <>
      <Button
        rounded
        primary
        dark={dark}
        bordered={bordered}
        block
        style={[
          {
            height: 62,
          },
        ]}
        onPress={selectDocument}
      >
        {loading && <AppActivityIndicator color="#ffffff" />}
        {!loading && (
          <Icon
            name="document"
            style={{ color: "rgb(63, 91, 122)", marginRight: 5 }}
          />
        )}
        <Text
          style={{
            padding: 0,
            paddingHorizontal: 16,
            fontFamily: StyleConstants.defaultFont,
          }}
        >
          {buttonTitle}
        </Text>
      </Button>
      {disclaimer && (
        <Text style={{ marginVertical: 20, fontSize: 12 }}>{disclaimer}</Text>
      )}
    </>
  );
};

export default UploadFile;
